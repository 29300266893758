/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { Image, Accordion } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import EM_LOGO from "../../assets/img/em_black_logo.svg";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation } from "react-router-dom";
import CommonLoader from "../Common/CommonLoader";
import { StartMainCommonLoader } from "../../redux/actions/adminActions";

const FAQ = () => {
    const { device_id, faqList, commonMainLoader } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const location = useLocation();
    const dispatch = useDispatch();
    const searchLocation = location?.search?.split("&");
    const faq_id = searchLocation[0]?.split("=")[1];
    const sub_category_id = searchLocation[1]?.split("=")[1];

    useEffect(() => {
        dispatch(StartMainCommonLoader({
            url: "help_desk_faq_list",
            loader: true,
        }));
        let param = { 
            "transmit": "broadcast", 
            "url": "help_desk_faq_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : "",
                "sub_category_id": sub_category_id,
                "order_by": "-updated_at"
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, [ websocket, sub_category_id ]);

    return(<div className="deactivate_account_wrapper" >
        <div className="main_form_Box">
            <div className="main_from_logo_header">
                <Image src={EM_LOGO} alt="logo" />
                <h4>FAQs</h4>
            </div>
            <div className="loginMainblog faq_wrapper">  
                <Scrollbars
                    style={{ height: "calc(100vh - 145px)" }}
                    renderView={props => <div {...props} className="view" />}
                >
                    <Accordion defaultActiveKey={faq_id}>
                        <div className="faq_logs_wrapper">
                            {faqList?.map((elm, index) => {
                                return(<Accordion.Item key={elm?.id} eventKey={elm?.id}>
                                    <Accordion.Header>{index + 1}. {elm?.question}</Accordion.Header>
                                    <Accordion.Body>
                                        <strong>Answer:</strong> {elm?.answer}
                                    </Accordion.Body>
                                </Accordion.Item>)
                            })}
                        </div>
                    </Accordion>
                </Scrollbars>              
            </div>
        </div>

        {/* COMMON LOADER */}
        {(commonMainLoader?.url === "help_desk_faq_list" && commonMainLoader?.loader) && (<CommonLoader />)}
    </div>)
}

export default FAQ;