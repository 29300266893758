/* eslint-disable */
import React, { useState, useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
import UploadFileAPI from '../../Api/UploadFileAPI';
import { Image, Spinner } from 'react-bootstrap';
import { GetBikeConditionsFilters } from '../../redux/actions/adminActions';
import { useDispatch } from 'react-redux';

const WebcamCapture = (props) => {
  const { CloseSidebar, 
          OpenCameraModal, 
          setUploadFileLoader,
          BikeCondtions, setBikeCondtions
  } = props;
  const webcamRef = useRef(null);
  const Dispatch = useDispatch();
  const [facingMode, setFacingMode] = useState(screen?.width > 600 ? 'user' : 'environment'); // 'user' for front, 'environment' for rear
  const [isLoaded, setIsLoaded] = useState(false);

  const videoConstraints = {
    facingMode: facingMode,
  };

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  const UploadImage = async (file, filename) => {
      setUploadFileLoader(true);
      const files = file;
      const formData = new FormData();
      formData.append("file", files, filename);
      const fileresponse = await UploadFileAPI(formData);
      if (fileresponse?.status === 200) {
          setUploadFileLoader(false);
          return fileresponse;
      } else {
          setUploadFileLoader(false);
      };
  };

  const [ tmprFile, settmprFile ] = useState({
    file: {},
    fileName: "",
    Image: "",
  })

  const handleUserMedia = useCallback(() => {
    setIsLoaded(true);
  }, []);

  // capture images
  const capture = useCallback(async () => {
      const imageSrc = webcamRef.current.getScreenshot();
      const file = await fetch(imageSrc).then(res=>res.blob()).then(res =>{
          return res;
      });
      const fileName = OpenCameraModal?.url?.replaceAll(" ", "_") + ".png";

      setTimeout(() => {
          settmprFile({
            ...tmprFile,
            file: file,
            fileName: fileName,
            Image: imageSrc,
          });
      }, 200);
  }, [webcamRef]);

  const CaptureImageUpload = async () => {
      const UploadeImage = await UploadImage(tmprFile?.file, tmprFile?.fileName);
      if(UploadeImage !== undefined) {
          const UpdateImageData = BikeCondtions?.map((elm) => {
            if(elm?.id === OpenCameraModal?.parent_id) {
              return{
                ...elm,
                Images: elm?.Images?.map((elz) => {
                  if(elz?.id === OpenCameraModal?.current_id) {
                    return {
                      ...elz,
                      data: UploadeImage,
                      base64_encode: tmprFile?.Image
                    };
                  } else {
                    return elz;
                  }
                })
              }
            } else {
              return elm;
            }
          });
          setBikeCondtions(UpdateImageData);
          Dispatch(GetBikeConditionsFilters(UpdateImageData));
    
          CloseSidebar();
          settmprFile({
            ...tmprFile,
            file: {},
            fileName: "",
            Image: "",
          });
      } else {
          CloseSidebar();
          settmprFile({
            ...tmprFile,
            file: {},
            fileName: "",
            Image: "",
          });
      };
  };

  // delete image
  const deleteImage = () => {
    settmprFile({
      ...tmprFile,
      file: {},
      fileName: "",
      Image: "",
    });
  };

  return (
    <div className='capture_camera_modal'>
      <div className='capture_camera_modal_header'>
          {OpenCameraModal?.url}
          <i className="bi bi-x-circle-fill" onClick={CloseSidebar}></i>
      </div>
      {tmprFile?.Image === "" ? (<div className='camera_wrappers'>
          {!isLoaded && (<h4 className='camera_loader'>
            <Spinner />
            Please wait, the camera will open in a few seconds.
          </h4>)}
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMedia={handleUserMedia} 
            onUserMediaError={() => setIsLoaded(false)} 
            // imageSmoothing={true}
            // forceScreenshotSourceSize={true}
            mirrored={true}
          />
          {isLoaded && (<div className='btn_group_manage'>
            <button className='btn capturebtn' onClick={capture}><i className="bi bi-camera"></i>&nbsp;Capture</button>
            <button className='btn deleteImagebtn' onClick={switchCamera}><i className="bi bi-arrow-repeat"></i>&nbsp;Switch Camera</button>
          </div>)}
      </div>) : (<div className='camera_wrappers'>
          <Image src={tmprFile?.Image} alt={tmprFile?.fileName} />
          <div className='btn_group_manage'>
            <button className='btn deleteimage' onClick={deleteImage}><i className="bi bi-trash"></i>&nbsp;Delete</button>
            <button className='btn capturebtn' onClick={CaptureImageUpload}><i className="bi bi-upload"></i>&nbsp;Upload</button>
          </div>
      </div>)}
    </div>
  );
};

export default WebcamCapture;
